import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

export const mapSearch = {
  order: 1,
  name: "Search",
  subcategory: "The map",
  icon: MagnifyingGlassIcon,
  iconColor: "text-teal-500",
  markdown: `# Search
  Search is often the fastest way to navigate Tower Hunt. It is available on {% inlineRouterLink %}Big Map{% /inlineRouterLink %} and {% inlineRouterLink %}Compact Map{% /inlineRouterLink %} within a property page. Continue reading to learn more about search.

  ## Start a search

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the search bar in the upper-left corner.
  2. Type what you're looking for into the search field. To narrow your search, you can include {% inlineRouterLink articleId="search" sectionName="Narrow your search with modifiers" %}modifiers{% /inlineRouterLink %}.
  3. Select an option from the list using your mouse or {% inlineKeyboardShortcut %}&uarr;{% /inlineKeyboardShortcut %}, {% inlineKeyboardShortcut %}&darr;{% /inlineKeyboardShortcut %}, and {% inlineKeyboardShortcut %}Enter{% /inlineKeyboardShortcut %}.
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}/{% /inlineKeyboardShortcut %} to focus the search bar.
  {% /callout %}

  ## Search result types

  By default, Tower Hunt searches across locations, properties, buildings, companies, contacts, and tasks. Refer to the table below to understand what happens when you select each type of result.

  | **Search result type** | **What happens** |
  |---|---|
  | {% inlineAppIcon iconName="marker" %}**Location**{% /inlineAppIcon %} | Center the map on the location |
  | {% inlineAppIcon iconName="buildings" %}**Property or building**{% /inlineAppIcon %} | Visit {% inlineRouterLink %}property page{% /inlineRouterLink %} |
  | {% inlineAppIcon iconName="briefcase" %}**Company**{% /inlineAppIcon %} | Visit {% inlineRouterLink %}company page{% /inlineRouterLink %} |
  | {% inlineAppIcon iconName="user" %}**Contact**{% /inlineAppIcon %} | Visit {% inlineRouterLink %}contact page{% /inlineRouterLink %} |
  | {% inlineAppIcon iconName="star" %}**Task**{% /inlineAppIcon %} | Opens {% inlineRouterLink %}Tasks section{% /inlineRouterLink %} of the {% inlineRouterLink %}Me tab{% /inlineRouterLink %} and filters to the selected task |

  ## Narrow your search with modifiers

  To narrow the scope of your results, you can add modifiers to your search terms. Refer to the table below for a list of modifiers you can use to search in Tower Hunt.

  | **How to narrow your search** | **Example** |
  |---|---|
  | Add {% inlineKeyboardShortcut %}#{% /inlineKeyboardShortcut %} to search for {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %} and {% inlineRouterLink %}buildings{% /inlineRouterLink %} | **#Atlantic Wharf**, **#400 Unicorn Park Drive**|
  | Add {% inlineKeyboardShortcut %}>{% /inlineKeyboardShortcut %} to search for {% inlineRouterLink %}companies{% /inlineRouterLink %} | **>Boston Properties**, **>CBRE**|
  | Add {% inlineKeyboardShortcut %}@{% /inlineKeyboardShortcut %} to search for {% inlineRouterLink %}contacts{% /inlineRouterLink %} | **@Mike**|
  | Add {% inlineKeyboardShortcut %}!{% /inlineKeyboardShortcut %} to search for {% inlineRouterLink %}tasks{% /inlineRouterLink %} | **!Call Steve**, **!Write market report**|

  ## Clear search

  If you decide you don't want to search or that you want to start over, click the **X** button on the right side of the search bar.

  {% callout type="tip" %}
  **Keyboard shortcut:** You can also press {% inlineKeyboardShortcut %}Esc{% /inlineKeyboardShortcut %} to clear.
  {% /callout %}`,
};
